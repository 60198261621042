import { useEffect, useRef, useState } from 'react';
import { Divider } from '@raylo-tech/raylopay-ui';
import {
  StyledFilterHeader,
  StyledFilterHeaderHeading,
  StyledFilterHeaderInfo,
  StyledFilterHeaderMain,
  StyledFilterHeaderProductCountWrapper,
  StyledFilterHeaderSortMenuWrapper,
  StyledFilterHeaderTriggerWrapper,
} from './styles';
import AppliedFilters from '../AppliedFilters/AppliedFilters';
import { useProductsContext } from '../../../hooks/useProductsContext';
import SortMenu from '../SortMenu/SortMenu';
import { getCategoryName } from '../../../utils/categoryHelpers';
import FilterSortTrigger from '../SortMenu/FilterSortTrigger';
import { FilterProductCount } from '../FilterProductCount/FilterProductCount';
import NEW_BREAKPOINTS from '@/constants/breakpoints';
import useMedia from 'use-media';

const FilterHeader = ({ scrolledPastGrid }: { scrolledPastGrid: boolean }) => {
  const {
    filters: { category },
  } = useProductsContext();
  const isMobileOrTablet = !useMedia({ minWidth: NEW_BREAKPOINTS.smallDesktop });
  const containerRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    let observerRefValue: HTMLDivElement | null = null;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!isMobileOrTablet || scrolledPastGrid) return;
        setIsSticky(!entry.isIntersecting);
      },
      {
        threshold: 1,
        rootMargin: '-141px 0px 0px 0px',
      },
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
      observerRefValue = containerRef.current;
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [containerRef, isMobileOrTablet, scrolledPastGrid]);

  useEffect(() => {
    setIsSticky(!scrolledPastGrid);
  }, [scrolledPastGrid]);

  return (
    <StyledFilterHeader data-testid="filter-header">
      <StyledFilterHeaderMain ref={containerRef}>
        <StyledFilterHeaderInfo>
          {category.length <= 1 && (
            <StyledFilterHeaderHeading data-testid="filter-category-header">
              {category?.[0] ? getCategoryName(category?.[0]) : 'All'}
            </StyledFilterHeaderHeading>
          )}

          <StyledFilterHeaderProductCountWrapper>
            <FilterProductCount />
          </StyledFilterHeaderProductCountWrapper>
        </StyledFilterHeaderInfo>

        <StyledFilterHeaderTriggerWrapper>
          <FilterSortTrigger isSticky={isSticky} />
        </StyledFilterHeaderTriggerWrapper>

        <StyledFilterHeaderSortMenuWrapper>
          <SortMenu />
        </StyledFilterHeaderSortMenuWrapper>
      </StyledFilterHeaderMain>

      <Divider height={24} />
      <AppliedFilters />
    </StyledFilterHeader>
  );
};

export default FilterHeader;
