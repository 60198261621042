import {
  StyledSortMenu,
  StyledSortMenuProductCountWrapper,
  StyledSortMenuSortByLabel,
  StyledSortSelect,
  StyledSortSelectWrapper,
} from './styles';
import { useProductsContext } from '../../../hooks/useProductsContext';
import Copy from '../../../elements/Copy';
import { ALL_SORT_OPTIONS } from '../utils';
import { FilterProductCount } from '../FilterProductCount/FilterProductCount';

const SortMenu = () => {
  const { handleSortProductsChange, activeSortLabel } = useProductsContext();

  return (
    <StyledSortMenu>
      <StyledSortMenuProductCountWrapper>
        <FilterProductCount />
      </StyledSortMenuProductCountWrapper>
      <StyledSortMenuSortByLabel>
        <Copy bold fontSize={16} lineHeight={20}>
          Sort by
        </Copy>
      </StyledSortMenuSortByLabel>
      <StyledSortSelectWrapper data-testid="sort-menu">
        <StyledSortSelect
          data-testid="sort-menu-select"
          value={activeSortLabel?.value}
          onChange={(event) => {
            const option = ALL_SORT_OPTIONS.find((opt) => opt.value === event.target.value);
            if (option) {
              handleSortProductsChange(option);
            }
          }}
        >
          {ALL_SORT_OPTIONS.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </StyledSortSelect>
      </StyledSortSelectWrapper>
    </StyledSortMenu>
  );
};

export default SortMenu;
