import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import NEW_BREAKPOINTS from '@/constants/breakpoints';
import { cardImageSizes } from './constants';
import { CSS_FONT_WEIGHT, palette } from '@raylo-tech/raylopay-ui';

const StyledConditionBadge = styled.div<{ $margin?: number }>`
  ${({ $margin }) => $margin && `margin-right: ${px2Rem($margin)}`};
  color: ${palette.charcoal[400]};
  font-size: ${px2Rem(12)};
  line-height: normal;
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  background-color: ${palette.charcoal[100]};
  border-radius: ${px2Rem(4)};
  padding: ${px2Rem(3)} ${px2Rem(6)} ${px2Rem(5)} ${px2Rem(6)};
`;

const StyledProductCard = styled.div<{ $hasMoreThanOneColour: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${px2Rem(16)} ${px2Rem(12)} ${px2Rem(16)} ${px2Rem(128)};
  align-items: flex-start;
  justify-content: center;
  background-color: ${palette.white};
  box-shadow:
    rgba(0, 0, 0, 0.14) 0px 1px 1px,
    rgba(0, 0, 0, 0.12) 0px 2px 1px,
    rgba(0, 0, 0, 0.2) 0px 1px 3px;
  cursor: pointer;
  position: relative;

  p {
    margin: 0;
    text-align: left;
  }

  .card-title {
    padding-bottom: ${px2Rem(4)};
  }

  .card-title p {
    display: inline;
  }

  .card-title p:first-of-type {
    margin-right: 0.25em;
  }

  .image-container {
    position: absolute;
    margin: 0;
    top: 50%;
    left: ${px2Rem(16)};
    transform: translateY(-50%);
    width: ${cardImageSizes.mobile.width}px;
    height: ${cardImageSizes.mobile.height}px;
    display: flex;
    justify-content: center;
  }

  .condition-badge-wrapper {
    display: flex;
    justify-content: flex-start;
    padding-bottom: ${px2Rem(12)};
  }

  .colour-swatches {
    align-items: flex-start;
    justify-content: flex-start;
    padding: ${px2Rem(8)} 0 ${px2Rem(12)} 0;
    gap: ${px2Rem(6)};

    span {
      margin: 0;
    }
  }

  .condition-badge-wrapper {
    padding-bottom: ${px2Rem(12)};
    justify-content: flex-start;
    gap: ${px2Rem(8)};

    ${({ $hasMoreThanOneColour }) => !$hasMoreThanOneColour && `padding-top: ${px2Rem(32)};`}
  }

  .price-wrapper p span {
    font-weight: ${CSS_FONT_WEIGHT.vars.regular};
    color: ${palette.charcoal[400]};
  }

  .price-wrapper p {
    display: block;
    padding-bottom: 0;
  }

  @media (min-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
    padding: ${px2Rem(28)};
    align-items: center;
    position: relative;

    p {
      text-align: center;
    }

    .card-title {
      padding-bottom: ${px2Rem(8)};
    }

    .card-title p {
      display: block;
    }

    .card-title p:first-of-type {
      margin-right: 0;
      padding-bottom: ${px2Rem(8)};
    }

    .price-wrapper p {
      font-size: ${px2Rem(18)};
      display: block;
      padding-bottom: 0;

      span {
        font-size: ${px2Rem(14)};
      }
    }

    .image-container {
      display: flex;
      margin: ${px2Rem(24)} 0 ${px2Rem(12)} 0;
      justify-content: center;
      height: 100%;
      position: relative;
      top: initial;
      left: initial;
      transform: none;
      width: ${px2Rem(cardImageSizes.desktop.width)};
      height: ${px2Rem(cardImageSizes.desktop.height)};
    }

    .colour-swatches {
      padding: ${px2Rem(12)} 0 ${px2Rem(16)} 0;
      justify-content: center;
    }

    .condition-badge-wrapper {
      justify-content: center;
      padding-bottom: ${px2Rem(16)};

      ${({ $hasMoreThanOneColour }) => !$hasMoreThanOneColour && `padding-top: ${px2Rem(40)};`}
    }
  }
`;

export { StyledConditionBadge, StyledProductCard };
