import { Icon, palette } from '@raylo-tech/raylopay-ui';
import Copy from '../../../elements/Copy';
import { useAppContext } from '../../../hooks/useAppContext';
import { track } from '../../../integrations/segment/segmentTracking';
import { StyledFilterSortTrigger, StyledFilterSortTriggerIcon } from './styles';

const FilterSortTrigger = ({ isSticky }: { isSticky: boolean }) => {
  const { setModalOpen, merchantDomain } = useAppContext();

  const openModal = () => {
    setModalOpen('filters');
    track('Filter and Sort Clicked', {
      customer_type: 'new',
      merchant: merchantDomain,
    });
  };

  return (
    <StyledFilterSortTrigger
      $isSticky={isSticky}
      onClick={openModal}
      data-testid="mobile-filter-trigger"
    >
      <Copy fontSize={16} lineHeight={20} color={palette.blue[500]} bold>
        Filter / Sort
      </Copy>
      <StyledFilterSortTriggerIcon data-testid="mobile-filter-icon">
        <Icon name="Filter" $height={24} $width={24} />
      </StyledFilterSortTriggerIcon>
    </StyledFilterSortTrigger>
  );
};
export default FilterSortTrigger;
