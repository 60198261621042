import React from 'react';
import { StyledColourSwatches } from './ColourSwatches.styles';
import { IColourSwatches } from './ColourSwatches.types';

export const ColourSwatches = ({ colours, dataTestId, leftAlign, className }: IColourSwatches) => (
  <StyledColourSwatches data-testid={dataTestId} className={className} leftAlign={leftAlign}>
    {colours.map((backgroundColor, i) => (
      <span
        data-testid={`${backgroundColor}-${i}`}
        key={`${backgroundColor}-${i}`}
        style={{ backgroundColor }}
      />
    ))}
  </StyledColourSwatches>
);
